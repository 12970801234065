import { ClientRemoteServiceEntity } from '@edgebox/api-rest-client';
import { CollectionResponse, ContentTypeEntry, RestClient } from './RestClient';
import { IPagedListResponse } from '@edgebox/data-definition-kit';

export const CONTENT_CLOUD_API_VERSION = '2025-01-01';

export function convertListResponse<T>(response: Promise<CollectionResponse<T>>, itemsPerPage: number): Promise<IPagedListResponse<T>> {
  return response.then((contentCloudResponse) => ({
    items: contentCloudResponse.items,
    itemsPerPage,
    numberOfPages: Math.ceil(contentCloudResponse.total / itemsPerPage),
    page: Math.round(contentCloudResponse.skip / itemsPerPage),
    totalNumberOfItems: contentCloudResponse.total,
  }));
}

export function getContentCloudSatelliteUrl(
  remoteService: ClientRemoteServiceEntity,
  {
    api,
    environmentSubdomain,
    service,
    version,
  }: {
    service: 'live' | 'cdn' | 'dev' | 'preview';
    environmentSubdomain: string;
    version?: string;
    api: 'rest' | 'graphql';
  }
) {
  return `${getContentCloudServiceUrl(remoteService, service, environmentSubdomain, version)}/${api}`;
}

const PATH_BASED_ROUTING_MATCHER = /^(https?:\/\/)([^/]+)\/([a-z0-9-]+)\/(publisher)$/;
const DOMAIN_BASED_ROUTING_MATCHER = /^(https?:\/\/)([a-z0-9-]+)\.(publisher)\.([^/]+)$/;

export function getContentCloudServiceUrl(
  remoteService: ClientRemoteServiceEntity,
  service: 'live' | 'cdn' | 'dev' | 'preview',
  environmentSubdomain: string,
  version?: string
): string;
export function getContentCloudServiceUrl(
  remoteService: ClientRemoteServiceEntity,
  service: 'publisher',
  region: string,
  version?: string
): string;
export function getContentCloudServiceUrl(
  remoteService: ClientRemoteServiceEntity,
  service: string,
  subdomain: string,
  version?: string
): string {
  const baseUrl = remoteService.baseUrl;

  // Use path-based routing in local environments.
  if (baseUrl.match(PATH_BASED_ROUTING_MATCHER)) {
    return `${baseUrl.replace(PATH_BASED_ROUTING_MATCHER, `$1$2/${subdomain}/${service}`)}/${version ?? CONTENT_CLOUD_API_VERSION}`;
  }

  // Use domain-based routing in all other environments.
  if (baseUrl.match(DOMAIN_BASED_ROUTING_MATCHER)) {
    return `${baseUrl.replace(DOMAIN_BASED_ROUTING_MATCHER, `$1${subdomain}.${service}.$4`)}/${version ?? CONTENT_CLOUD_API_VERSION}`;
  }

  throw new Error(`Invalid baseURL for Content Cloud: ${baseUrl}`);
}

let contentTypeCacheFor: string = '';
let contentTypeCache: ContentTypeEntry[] | null = null;
export async function loadAllContentTypes(client: RestClient): Promise<ContentTypeEntry[]> {
  const cacheFor = client.cacheId;

  if (contentTypeCacheFor === cacheFor && contentTypeCache) {
    return contentTypeCache;
  }

  let totalItems: number;
  let page = 0;
  let contentTypes: ContentTypeEntry[] = [];
  do {
    const currentPage = await client.contentTypeCollection({ skip: page * 100, limit: 100 });

    totalItems = currentPage.total;

    contentTypes = contentTypes.concat(currentPage.items);

    contentTypes.sort((a, b) => a.name.localeCompare(b.name));

    page++;
  } while (page < Math.ceil(totalItems / 100));

  contentTypeCacheFor = client.cacheId;
  contentTypeCache = contentTypes;

  return contentTypes;
}

const numberFormatter = new Intl.NumberFormat();
export function getNumberDisplay(number: number) {
  return numberFormatter.format(number);
}

const fixedNumberFormatter = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
});
export function getFileSizeDisplay(sizeInBytes: number) {
  if (sizeInBytes > 1_024 * 1_024 * 1_024) {
    return `${fixedNumberFormatter.format(sizeInBytes / 1_024 / 1_024 / 1_024)} GiB`;
  }

  if (sizeInBytes > 1_024 * 1_024) {
    return `${fixedNumberFormatter.format(sizeInBytes / 1_024 / 1_024)} MiB`;
  }

  if (sizeInBytes > 1_024) {
    return `${fixedNumberFormatter.format(sizeInBytes / 1_024)} KiB`;
  }

  return `${sizeInBytes} Bytes`;
}
