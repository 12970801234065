import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquareCheck } from '@fortawesome/pro-light-svg-icons/faSquareCheck';
import { faCalendarDays } from '@fortawesome/pro-light-svg-icons/faCalendarDays';
import { faSquare1 } from '@fortawesome/pro-light-svg-icons/faSquare1';
import { faText } from '@fortawesome/pro-light-svg-icons/faText';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faPuzzlePiece } from '@fortawesome/pro-light-svg-icons/faPuzzlePiece';
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion';
import { ContentTypeEntry, ContentTypePropertyEntry } from '../../RestClient';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faBracketsCurly } from '@fortawesome/pro-light-svg-icons/faBracketsCurly';
import { faSitemap } from '@fortawesome/pro-light-svg-icons/faSitemap';

interface Props {
  contentTypePropertyEntry: ContentTypePropertyEntry;
  contentTypeLink?: ContentTypeEntry;
}

const STANDARD_TYPES: { [key in string]?: { icon: IconProp; name: string; nameBig?: string } } = {
  Boolean: {
    icon: faSquareCheck,
    name: 'Boolean',
  },
  Date: {
    icon: faCalendarDays,
    name: 'Date',
  },
  String: {
    icon: faText,
    name: 'Short text',
    nameBig: 'Long text',
  },
  Int: {
    icon: faSquare1,
    name: 'Integer',
    nameBig: 'Big integer',
  },
  Float: {
    icon: faSquare1,
    name: 'Float',
    nameBig: 'Double',
  },
  JSON: {
    icon: faBracketsCurly,
    name: 'JSON',
  },
};

export function ContentTypePropertyType({ contentTypePropertyEntry, contentTypeLink }: Props) {
  let display = STANDARD_TYPES[contentTypePropertyEntry.type];

  if (!display) {
    if (contentTypeLink) {
      display = {
        icon: contentTypePropertyEntry.isParentLink ? faSitemap : contentTypeLink.isInline ? faPuzzlePiece : faLink,
        name: contentTypeLink.name,
      };
    } else if (contentTypePropertyEntry.isLink) {
      display = {
        icon: contentTypePropertyEntry.isParentLink ? faSitemap : faLink,
        name: contentTypePropertyEntry.type,
      };
    } else {
      // Check if we can find a shared suffix, e.g. FileMedia + ImageMedia => Media.
      if (contentTypePropertyEntry.allowedTypes?.length) {
        let suffixLength: number;
        let suffix: string;
        for (suffixLength = 0; suffixLength < contentTypePropertyEntry.allowedTypes[0].length; suffixLength++) {
          const testSuffix = contentTypePropertyEntry.allowedTypes[0].substring(
            contentTypePropertyEntry.allowedTypes[0].length - suffixLength - 1
          );

          if (contentTypePropertyEntry.allowedTypes.find((c) => !c.endsWith(testSuffix))) {
            break;
          }
        }

        suffix = contentTypePropertyEntry.allowedTypes[0].substring(contentTypePropertyEntry.allowedTypes[0].length - suffixLength);

        if (suffix) {
          display = {
            icon: faQuestion,
            name: suffix,
          };
        }
      }
    }
  }

  if (!display) {
    display = {
      icon: faQuestion,
      name: contentTypePropertyEntry.type,
    };
  }

  const name = contentTypePropertyEntry.isBig && display.nameBig ? (display.nameBig ?? display.name) : display.name;

  return (
    <span>
      <FontAwesomeIcon icon={display.icon} className="me-2" />
      {contentTypeLink ? <Link to={`/content-cloud/content-model/${contentTypeLink.id}`}>{name}</Link> : name}
    </span>
  );
}
