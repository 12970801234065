export interface SystemMetadata<Type extends string = string> {
  type: Type;
  id?: string;
  customId?: string;
  uuid?: string;
  entryCreatedAt?: string;
  entryVersion?: number;
  environment?: EnvironmentEntry | EntryLink<'Environment'>;
  firstPublishedAt?: string;
  isPublished?: boolean;
  locale?: LocaleEntry | EntryLink<'Locale'>;
  localizationVersion?: number;
  publishedAt?: string;
  space?: SpaceEntry | EntryLink<'Space'>;
  versionCreatedAt?: string;
  versionId?: string;
  name?: string;
  description?: string;
  externalLinks?: ExternalEntryLinkEntry[];
}

export enum ExternalEntryLinkType {
  Source = 'Source',
  Target = 'Target',
  Mapped = 'Mapped',
  Display = 'Display',
}

export interface ExternalEntryLinkEntry {
  sys: SystemMetadata<'ExternalEntryLink'>;

  linkType: ExternalEntryLinkType;

  spaceUuid?: string;
  environmentUuid?: string;
  siteUuid?: string;
  domain: string;

  externalTargetEntryId?: string;
  externalTargetVersionId?: string;

  authorName?: string;
  authorEmail?: string;
  publisherName?: string;
  publisherEmail?: string;

  publicMetadata?: Record<string, any>;
  privateMetadata?: Record<string, any>;

  canonicalUrl?: string;
  prettyUrl?: string;
  editUrl?: string;
  deleteUrl?: string;
  versionUrl?: string;
}

export type FeatureConfig = Record<string, any>;

export interface EntryLink<LinkType extends string = string> {
  sys: {
    id: string;
    type: 'Link';
    linkType: LinkType;
  };
}

export interface EntrySystemMetadata<Type extends string = string> extends SystemMetadata<Type> {
  id: string;
  entryCreatedAt: string;
  entryVersion: number;
  isPublished: boolean;
  versionCreatedAt: string;
  versionId: string;
}

export interface EntrySystemMetadataWithSpace<Type extends string = string> extends EntrySystemMetadata<Type> {
  environment: EnvironmentEntry;
  locale: LocaleEntry;
  space: SpaceEntry;
}

export interface CollectionResponse<Type> {
  sys: SystemMetadata;
  items: Type[];
  limit: number;
  skip: number;
  total: number;
}

export type EntryResponse<Type> = Type & { sys: SystemMetadata };

export interface SpaceEntry {
  sys: EntrySystemMetadata<'Space'>;

  id: string;
  uuid: string;

  isPublished: boolean;

  name: string;

  featureConfig: FeatureConfig;
}
export interface EnvironmentEntry {
  sys: EntrySystemMetadata<'Environment'>;
}
export interface LocaleEntry {
  sys: EntrySystemMetadata<'Locale'>;

  id: string;
  code: string;

  isPublished: boolean;

  name: string;
}

export interface ContentTypePropertyEntry {
  sys: EntrySystemMetadataWithSpace<'ContentTypeProperty'>;

  customId: string;
  id: string;
  machineName: string;

  isPublished: boolean;

  type: string;
  isArray: boolean;
  isBig: boolean;
  isItemRequired: boolean;
  isLink: boolean;
  isLocalized: boolean;
  isParentLink: boolean;
  isRequired: boolean;

  allowedTypes?: string[];

  name: string;
  description?: string;
}
export interface ContentTypeEntry {
  sys: EntrySystemMetadataWithSpace<'ContentType'>;

  customId: string;
  id: string;
  machineName: string;

  isPublished: boolean;

  isAsset: boolean;
  isIndependent: boolean;
  isInline: boolean;
  isTaxonomy: boolean;

  properties: ContentTypePropertyEntry[];

  name: string;
  description?: string;
}
export interface ContentEntry {
  sys: EntrySystemMetadata<'Content'> & { contentType: EntryLink<'ContentType'> | ContentTypeEntry };

  fields: Record<string, any>;

  tag?: TagEntry;
  asset?: AssetEntry;
}
export enum MimeTypeGroup {
  Image = 'image',
  Audio = 'audio',
  Video = 'video',
  RichText = 'richtext',
  Presentation = 'presentation',
  Spreadsheet = 'spreadsheet',
  PdfDocument = 'pdfdocument',
  Archive = 'archive',
  Code = 'code',
  Markup = 'markup',
  Plaintext = 'plaintext',
  Attachment = 'attachment',
  Other = 'other',
}
export interface AssetEntry {
  sys: EntrySystemMetadata<'Asset'>;

  id: string;

  isPublished: boolean;

  fields: {
    hash: string;
    mimeType: string;
    mimeTypeGroup: MimeTypeGroup;
    size: number;
    customVersionId?: string;
    details?: {
      image?: {
        width: number;
        height: number;
      };
    };
    imageUrl?: string;
    downloadUrl?: string;
    embedUrl?: string;
    fileName: string;
  };

  name: string;
}
export interface TagEntry {
  sys: EntrySystemMetadata<'Tag'>;

  id: string;

  isPublished: boolean;

  name: string;
}

type QueryParameters = Record<string, unknown>;

export interface CollectionRequest extends QueryParameters {
  skip?: number;
  limit?: number;
}

export interface ContentTypeCollectionRequest extends CollectionRequest {}
export interface ContentTypeEntryRequest extends QueryParameters {
  machineName?: string;
  customId?: string;
  id?: string;
}

export interface ContentCollectionRequest extends CollectionRequest {
  locale?: string;
  include?: number;

  content_type?: string;
  order?: string[] | string;
}
export interface ContentEntryRequest extends QueryParameters {
  locale?: string;
  include?: number;

  customId?: string;
  id?: string;
  uuid?: string;
}

export interface LocaleCollectionRequest extends CollectionRequest {
  'sys.name'?: string;
}

export interface AssetCollectionRequest extends CollectionRequest {
  locale?: string;

  'sys.name'?: string;
}
export interface AssetEntryRequest extends QueryParameters {
  locale?: string;

  id?: string;
}

export interface TagCollectionRequest extends CollectionRequest {
  locale?: string;

  'sys.name'?: string;
}
export interface TagEntryRequest extends QueryParameters {
  locale?: string;

  id?: string;
}

export type RestInterfaceDataTypes = 'space' | 'content_types' | 'locales' | 'entries' | 'assets' | 'tags';

export interface ImageSettings extends QueryParameters {
  /**
   * E.g. 300 for 300 pixels.
   */
  width?: number;
  /**
   * E.g. 300 for 300 pixels.
   */
  height?: number;
  /**
   * E.g. #ffffff for white.
   */
  background?: string;
  /**
   * E.g. "auto" or "100,200" in pixels.
   *
   * "auto": use the Drupal focal point setting if available. Otherwise, check the image itself.
   */
  gravity?: 'auto' | string;
  /**
   * E.g. "webp" for optimized images.
   */
  format?: 'jpeg' | 'png' | 'gif' | 'webp' | 'avif' | 'svg';
  /**
   * E.g. "scale-down" to prevent upscaling.
   */
  fit?: 'scale-down' | 'contain' | 'cover' | 'crop' | 'pad';
  /**
   * E.g. 100 for highest quality or 0 for lowest.
   *
   * Only relevant for JPEG output.
   */
  quality?: number;
}

function serializeQuery(queryParameters: QueryParameters) {
  return Object.entries(queryParameters)
    .map(
      ([name, value]) =>
        `${encodeURIComponent(name)}=${encodeURIComponent(typeof value === 'string' ? value : value !== null && value !== undefined ? (Array.isArray(value) ? value.join(',') : value.toString()) : '')}`
    )
    .join('&');
}

export function getImageUrl(originalUrl: string, settings: ImageSettings) {
  const query = serializeQuery(settings);
  return originalUrl.includes('?') ? `${originalUrl}&${query}` : `${originalUrl}?${query}`;
}

export class RestClient {
  constructor(
    private readonly options: {
      baseUrl: string;
      accessToken: string;
      spaceDomainKey: string;
      environmentDomainKey?: string;
    }
  ) {}

  get cacheId(): string {
    if (this.options.environmentDomainKey) {
      return `${this.options.spaceDomainKey}-${this.options.environmentDomainKey}`;
    }

    return this.options.spaceDomainKey;
  }

  async query(type: RestInterfaceDataTypes, id?: string, queryParameters?: Record<string, unknown>): Promise<any> {
    const response = await fetch(
      `${this.options.baseUrl}/${type}${id ? `/${id}` : ''}${queryParameters ? `?${serializeQuery(queryParameters)}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${this.options.accessToken}`,
        },
      }
    );

    if (!response.ok) {
      if (response.status === 404) {
        return null;
      }

      throw new Error(await response.text());
    }

    const responseBody = await response.json();

    responseBody.errors && console.log(responseBody.errors);

    return responseBody;
  }

  async contentTypeCollection(request?: ContentTypeCollectionRequest): Promise<CollectionResponse<ContentTypeEntry>> {
    return this.query('content_types', undefined, request);
  }
  async contentTypeEntry(request?: ContentTypeEntryRequest): Promise<ContentTypeEntry | null> {
    if (request?.id) {
      return this.query('content_types', request.id);
    }
    const response = await this.query('content_types', undefined, request);

    return response?.items?.[0] ?? null;
  }

  async contentCollection(request?: ContentCollectionRequest): Promise<CollectionResponse<ContentEntry>> {
    return this.query('entries', undefined, request);
  }
  async contentEntry(request?: ContentEntryRequest): Promise<ContentEntry | null> {
    if (request?.id) {
      return this.query('entries', request.id, request);
    }
    const response = await this.query('entries', undefined, request);

    return response?.items?.[0] ?? null;
  }

  async spaceEntry(): Promise<SpaceEntry> {
    return this.query('space');
  }

  async localeCollection(request?: LocaleCollectionRequest): Promise<CollectionResponse<LocaleEntry>> {
    return this.query('locales', undefined, request);
  }

  async assetCollection(request?: AssetCollectionRequest): Promise<CollectionResponse<AssetEntry>> {
    return this.query('assets', undefined, request);
  }
  async assetEntry(request?: AssetEntryRequest): Promise<AssetEntry | null> {
    if (request?.id) {
      return this.query('assets', request.id, request);
    }
    const response = await this.query('assets', undefined, request);

    return response?.items?.[0] ?? null;
  }

  async tagCollection(request?: TagCollectionRequest): Promise<CollectionResponse<TagEntry>> {
    return this.query('tags', undefined, request);
  }
  async tagEntry(request?: TagEntryRequest): Promise<TagEntry | null> {
    if (request?.id) {
      return this.query('tags', request.id, request);
    }
    const response = await this.query('tags', undefined, request);

    return response?.items?.[0] ?? null;
  }
}
